import React from 'react';
import styled from "styled-components"
import { ThemeContext } from './ThemeContext';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  padding: 6px 4px;
  width: 45px;
  height: 24px;
  background: none;
  border-radius: 24px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 4px;
    border-radius: 50%;
    background: hsl(52deg, 80%, 56%);
    background-size: 150% 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  /*&:not(:checked) + ${Switch} {
    &:before {
      transform: rotate(-90deg);
    }
  }*/

  &:checked + ${Switch} {

    &:before {
      transform: rotate(90deg);
      background: radial-gradient(ellipse farthest-corner at 33% 100%, var(--color-background) 45%, hsl(52deg, 80%, 56%) 47%);
    }
  }
`;


const ColorModeToggle = () => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);

  if (!colorMode) {
    return null;
  }

  return (
    <Label>
      <Input
        aria-label={colorMode === 'dark' ? 'Toggle Light Mode' : 'Toggle Dark Mode'}
        type="checkbox"
        checked={colorMode === 'dark'}
        onChange={ev => {
          setColorMode(ev.target.checked ? 'dark' : 'light');
        }}
      />
      <Switch />
    </Label>
  );
};

export default ColorModeToggle;