import * as React from "react"
import Layout from '../../components/Layout'
import MegaTic from "../../components/MegaTic"

const MegaTicPage = () => {
  return (
    <Layout>
      <MegaTic />
    </Layout>
  )
}

export default MegaTicPage