import * as React from "react"
import { useState, useEffect } from "react"
import styled from "styled-components"

const BaseTile = styled.button`
  height: 100%;
  padding: 5%;
  min-width: 30px;
  aspect-ratio: 1/1;
  box-sizing: unset;
  border: none;
  background: none;
  background-color: ${props => props.complete ? ownerColor(props.winner) : (props.available ? 'hsla(142, 42%, 76%, 1)' : ownerColor(null))};
  div {
    line-height: 0;
    color: var(--color-background);
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  content-sizing: border-box;
  aspect-ratio: 1/1;
  ::after {
    display: none;
  }
`

const Board = styled(Grid)`
  padding: 10px;
  margin-bottom: 1rem;
  max-width: 480px;
  max-height: 480px;
  /*grid-template-columns: repeat(3, minmax(40px, minmax(1fr, auto)));
  grid-template-rows: repeat(3, minmax(40px, minmax(1fr, auto)));*/
  row-gap: 18px;
  column-gap: 18px;
`

const BaseBoard = styled(Grid)`
  column-gap: 10px;
  row-gap: 10px;
`

const StratSelectForm = styled.form`
  display: flex;
  align-items: end;
`

const StratSelect = styled.span`
  padding: 0 8px;

  select {
    height: 2rem;
  }
`

const StratButton = styled.button`
  height: 2rem;
  margin-left: 8px;
  alignSelf: center;
`

const ColorChecker = () => {
  return (<>
    <div style={{ width: 80, height: 80, backgroundColor: 'hsla(142, 42%, 76%, 1)', color: 'var(--color-background)' }}>A</div>
    <div style={{ width: 80, height: 80, backgroundColor: ownerColor(null), color: 'var(--color-background)' }}>U</div>
    <div style={{ width: 80, height: 80, backgroundColor: ownerColor(0), color: 'var(--color-background)' }}>D</div>
    <div style={{ width: 80, height: 80, backgroundColor: ownerColor(1), color: 'var(--color-background)' }}>X</div>
    <div style={{ width: 80, height: 80, backgroundColor: ownerColor(2), color: 'var(--color-background)' }}>O</div>
  </>)
}

const renderOwner = (i) => {
  return ['', 'X', 'O'][i]
}

const ownerColor = (i) => {
  if (i === null) {
    return 'hsla(0, 0%, 50%, 1)'
  }
  return [
    'hsla(0, 0%, 30%, 1)',
    'hsla(183, 66%, 30%, 1)',
    'hsla(11, 80%, 69%, 1)'
  ][i]
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

const getStrat = (player, x, o) => {
  if (player && player > 0 && player < 3) {
    return player === 1 ? x : o;
  }
  return null
}

const url = process.env.MEGATIC_URL

const MegaTic = () => {
  const [xStrat, setXStrat] = useState('random')
  const [oStrat, setOStrat] = useState('local')
  const [started, setStarted] = useState(false)
  const [game, setGame] = useState(null)
  const [gameState, setGameState] = useState(null)
  const [move, setMove] = useState([])
  const [showMoves, toggleShowMoves] = useState(false)
  const [delay, setDelay] = useState(200)

  useEffect(() => {
    if (started) {
      fetch(`${url}/new?x=${xStrat}&o=${oStrat}`).then(res => res.json()).then(data => {
        setGame(data)
        fetch(`${url}/state`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
          setGameState(data);
        })
      })
    }
  }, [started]);

  useEffect(() => {
    const moveAI = async () => {
      const player = gameState.next;
      const strat = getStrat(player, xStrat, oStrat)
      if (strat) {
        if (strat !== 'human') {
          await sleep(delay);
          fetch(`${url}/move/${player}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(game)
          }).then(res => res.json()).then(data => {
            setGame(data);
            console.log(data)
            setMove([])
            fetch(`${url}/state`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then(res => res.json()).then(data => {
              setGameState(data);
            })
          })
        }
      }
    }
    if (started && gameState && !gameState.over) {
      moveAI();
    }
    if (started && gameState && gameState.over) {
      setStarted(false)
    }
  }, [gameState])

  useEffect(() => {
    const player = gameState && gameState.next ? gameState.next : 0
    const strat = getStrat(player, xStrat, oStrat)
    if (strat === 'human' && game && gameState && move.length === 2) {
      fetch(`${url}/move/${gameState.next}/${move[0]}/${move[1]}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(game)
      }).then(res => res.json()).then(data => {
        setGame(data);
        setMove([])
        fetch(`${url}/state`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
          setGameState(data);
        })
      })
    }
  }, [move])

  return (
    <div style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p>This is a work in progress, which I'm leaving here in the spirit of building in the open.</p>
      <StratSelectForm>
        <StratSelect>
          <div style={{ backgroundColor: ownerColor(1), marginBottom: 5, padding: 5, borderRadius: 10, color: 'var(--color-background)' }}>
            <label htmlFor="xstrat">Player X</label>
          </div>
          <div>
            <select
              name="xstrat"
              id="xstrat"
              value={xStrat}
              onChange={e => setXStrat(e.target.value)}
              disabled={started}
            >
              <option value="human">Human</option>
              <option value="random">AI: Random</option>
              <option value="local">AI: Medium 1</option>
              <option value="local-defense">AI: Medium 2</option>
            </select>
          </div>
        </StratSelect>
        <StratSelect>
          <div style={{ backgroundColor: ownerColor(2), marginBottom: 5, padding: 5, borderRadius: 10, color: 'var(--color-background)' }}>
            <label htmlFor="ostrat">Player O</label>
          </div>
          <div>
            <select
              name="ostrat"
              id="ostrat"
              value={oStrat}
              onChange={e => setOStrat(e.target.value)}
              disabled={started}
            >
              <option value="human">Human</option>
              <option value="random">AI: Random</option>
              <option value="local">AI: Medium 1</option>
              <option value="local-defense">AI: Medium 2</option>
            </select>
          </div>
        </StratSelect>
        <StratButton onClick={(event) => { event.preventDefault(); setStarted(true); setGame(null); setGameState(null); }} disabled={started}>Start</StratButton>
      </StratSelectForm>
      {
        gameState && !gameState.over &&
        <h2>Next player: {gameState ? renderOwner(gameState.next) : ''}</h2>
      }
      {
        gameState && gameState.over && gameState.winner === 0 &&
        <h2>It's a draw!</h2>
      }
      {
        gameState && gameState.over && gameState.winner > 0 &&
        <h2>The winner is: {renderOwner(gameState.winner)}!</h2>
      }
      <div style={{ display: 'grid' }}>
        <Board>
          {
            game && game.board.tiles.map((t, i) => {
              const nestedTiles = t.nestedBoard.tiles
              const complete = t.nestedBoard.complete || (gameState && gameState.over)
              const winner = t.nestedBoard.winner
              return (
                <BaseBoard key={i}>
                  {nestedTiles.map((nt, ni) => {
                    const available = gameState ? (i in gameState.validMoves ? (gameState.validMoves[i].includes(ni) ? true : false) : false) : false;
                    return (
                      <BaseTile key={ni}
                        onClick={() => setMove([i, ni])}
                        disabled={!available}
                        available={available}
                        complete={complete}
                        winner={winner}
                      >
                        <div>
                          {(!showMoves && game.board.complete && game.board.winner > 0) || (!showMoves && complete && winner > 0) ? renderOwner(winner) : renderOwner(nt.owner)}
                        </div>
                      </BaseTile>
                    )
                  })}
                </BaseBoard>
              )
            })
          }
        </Board>
      </div>
      {
        gameState &&
        <div><button onClick={() => toggleShowMoves(!showMoves)}>{showMoves ? 'Hide' : 'Show'} moves</button></div>
      }
    </div >
  )
}

export default MegaTic